<template>

    <!-- 活動履歴 -->
    <section class="section">
        <div class="section-title">
            <h3 class="">活動履歴</h3>
            <button class="btn btn-link" @click="openAllAccordion()" v-if="is_open_activities.length < project.activities.length">全て展開</button>
            <button class="btn btn-link" @click="closeAllAccordion()" v-else>全て閉じる</button>
        </div>

        <div class="accordion">

            <template v-for="activity, index in project.activities" :key="activity.activity_id">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <!-- bootstrapのaccordion機能を利用している -->
                        <button @click="toggleAccordion(index)" class="accordion-button" :class="{'collapsed': is_open_activities.includes(index) === false}" type="button">
                            <!-- 活動種別表示 -->
                            <span
                                class="badge me-2"
                                :class="{
                                    'bg-danger': activity.activity_type === ActivityType.MEETING,
                                    'bg-success': activity.activity_type === ActivityType.APPOINTMENT,
                                    'bg-light': activity.activity_type === ActivityType.APPROACH,
                                }"
                            >
                                {{ activity.activity_detailed_type_label }}
                            </span>
                            <span class="d-inline-block ms-2 me-3 fs-7 nowrap">{{ $helper.ymd(activity.activity_date) }}</span>
                            <span class="fw-bold fs-7">{{ activity.user.user_name }}</span>
                        </button>
                    </h2>
                    <div class="accordion-collapse collapse" :class="{'show': is_open_activities.includes(index) === true}">
                        <div class="accordion-body">

                            <!-- 閲覧 -->
                            <!-- アプローチ記録 -->
                            <template v-if="activity.activity_type === ActivityType.APPROACH">
                                <div class="bg-light mb-3 p-3">
                                    <div class="row mb-3">
                                        <div class="col-18">
                                            <div class="form-label">作成日時</div>
                                            <div class="form-readonly">{{ $helper.ymdhi(activity.created_datetime) }}</div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-36">
                                            <label class="form-label">内容</label>
                                            <div class="form-readonly can-visible prewrap">{{ activity.approach_detail }}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <!-- アポ取得 -->
                            <template v-else-if="activity.activity_type === ActivityType.APPOINTMENT">
                                <div class="bg-light mb-3 p-3">
                                    <div class="row mb-3">
                                        <div class="col-18">
                                            <div class="form-label">アポイント日時</div>
                                            <div class="form-readonly">{{ $helper.ymdhi(activity.appointment_datetime) }}</div>
                                        </div>
                                        <div class="col-18">
                                            <div class="form-label">営業担当</div>
                                            <div class="form-readonly">{{ activity.sales_user?.user_name }}</div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-18">
                                            <div class="form-label">アポ取得リスト</div>
                                            <div class="form-readonly">{{ activity.appointment_source?.appointment_source_name }}</div>
                                        </div>
                                        <div class="col-18">
                                            <div class="form-label">作成日時</div>
                                            <div class="form-readonly">{{ $helper.ymdhi(activity.created_datetime) }}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">備考</label>
                                        <div class="form-readonly can-visible prewrap">{{ activity.appointment_note }}</div>
                                    </div>
                                </div>
                            </template>
                            <!-- 営業報告 -->
                            <template v-else>
                                <div class="bg-light mb-3 p-3">
                                    <div class="row mb-3">
                                        <div class="col-18">
                                            <div class="form-label">作成日時</div>
                                            <div class="form-readonly">{{ $helper.ymdhi(activity.created_datetime) }}</div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <label class="form-label">内容</label>
                                        <div class="form-readonly can-visible prewrap">{{ activity.meeting_detail }}</div>
                                    </div>
                                </div>
                            </template>

                            <!-- 編集 -->
                            <div class="text-end">
                                <button v-if="$store.getters['auth/canEditProject']" @click="openModal(activity)" type="button" class="btn btn-outline-primary" data-bs-toggle="modal" :data-bs-target="`#activityModal${index}`"><i class="bi bi-pencil-square"></i> 編集</button>
                                <!-- 活動記録モーダル -->
                                <!--
                                    bootstrapのモーダル機能を利用している
                                    showクラスが追加されると開く
                                    class="modal fade show"
                                -->
                                <div class="modal fade" :id="`activityModal${index}`" tabindex="-1" :aria-labelledby="`activityModalLabel${index}`" aria-hidden="true">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h3 class="modal-title" :id="`activityModalLabel${index}`">活動履歴 - {{ ActivityType.get(activity_to_update.activity_type) }}</h3>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>

                                            <form @submit.prevent="update()">
                                                <!-- アプローチ記録 -->
                                                <template v-if="activity_to_update.activity_type === ActivityType.APPROACH">
                                                    <div class="modal-body text-start">
                                                        <div class="row mb-3">
                                                            <div class="col-18">
                                                                <label class="form-label">件名</label>
                                                                <form-select
                                                                    v-model="activity_to_update.activity_detailed_type"
                                                                    :options="ActivityDetailedType.approachOptions()"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-18">
                                                                <label class="form-label">割り当て先 <i class="required"></i></label>
                                                                <form-select-search
                                                                    v-model="activity_to_update.user.user_id"
                                                                    :options="options_user"
                                                                    empty_option="----"
                                                                    :required="true"
                                                                ></form-select-search>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3 align-items-end">
                                                            <div class="col-9">
                                                                <label class="form-label">活動日 <i class="required"></i></label>
                                                                <form-input-date v-model="activity_to_update.activity_date" :required=true></form-input-date>
                                                            </div>
                                                            <div class="col-9">
                                                                <form-input-time v-model="activity_to_update.activity_time" :required=true></form-input-time>
                                                            </div>
                                                            <div class="col-18">
                                                                <label class="form-label">ステータス</label>
                                                                <form-select
                                                                    v-model="activity_to_update.status"
                                                                    :options="Status.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-36">
                                                                <label class="form-label">内容</label>
                                                                <form-textarea v-model="activity_to_update.approach_detail"></form-textarea>
                                                            </div>
                                                        </div>

                                                        <h4 class="mt-5 mb-3"><i class="bi bi-megaphone-fill text-primary"></i> お客様の声</h4>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">場面</label>
                                                                <form-select
                                                                    v-model="activity_to_update.voice_scene"
                                                                    :options="VoiceScene.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">お客様の声</label>
                                                                <form-textarea v-model="activity_to_update.voice_content"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">経緯</label>
                                                                <form-textarea v-model="activity_to_update.voice_background"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">対応</label>
                                                                <form-textarea v-model="activity_to_update.voice_action"></form-textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <!-- アポ取得 -->
                                                <template v-else-if="activity_to_update.activity_type === ActivityType.APPOINTMENT">
                                                    <div class="modal-body text-start">
                                                        <div class="row mb-3 align-items-end">
                                                            <div class="col-9">
                                                                <label class="form-label">件名</label>
                                                                <form-select
                                                                    v-model="activity_to_update.activity_detailed_type"
                                                                    :options="ActivityDetailedType.appointmentOptions()"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-9">
                                                                <label class="form-label">割り当て先 <i class="required"></i></label>
                                                                <form-select-search
                                                                    v-model="activity_to_update.user.user_id"
                                                                    :options="options_user"
                                                                    empty_option="----"
                                                                    :required="true"
                                                                ></form-select-search>
                                                            </div>
                                                            <div class="col-9">
                                                                <label class="form-label">活動日 <i class="required"></i></label>
                                                                <form-input-date v-model="activity_to_update.activity_date" :required=true></form-input-date>
                                                            </div>
                                                            <div class="col-9">
                                                                <form-input-time v-model="activity_to_update.activity_time" :required=true></form-input-time>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-9">
                                                                <label class="form-label">アポ取得リスト</label>
                                                                <form-select-object
                                                                    v-model="activity_to_update.appointment_source"
                                                                    :options="options_appointment_source"
                                                                    option_value="appointment_source_id"
                                                                    option_label="appointment_source_name"
                                                                    empty_option="----"
                                                                ></form-select-object>
                                                            </div>
                                                            <div class="col-9">
                                                                <label class="form-label">営業担当</label>
                                                                <form-select-search
                                                                    v-model="activity_to_update.sales_user.user_id"
                                                                    :options="options_user"
                                                                    empty_option="----"
                                                                    @select="changeSalesUser"
                                                                ></form-select-search>
                                                            </div>
                                                            <div class="col-14">
                                                                <label class="form-label">アポイント日時</label>
                                                                <form-input-datetime v-model="activity_to_update.appointment_datetime"></form-input-datetime>
                                                            </div>
                                                            <div class="col-4">
                                                                <label class="form-label">打合せ時間</label>
                                                                <div class="input-group">
                                                                    <form-input-number v-model="activity_to_update.meeting_minutes"></form-input-number>
                                                                    <div class="input-group-text">分</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label class="form-label">備考</label>
                                                            <form-textarea v-model="activity_to_update.appointment_note"></form-textarea>
                                                        </div>
                                                        <h4 class="mt-5 mb-4"><i class="bi bi-briefcase-fill text-primary"></i> 営業への引継事項</h4>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">会社名</label>
                                                                <form-input v-model="activity_to_update.company_name"></form-input>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">担当者名</label>
                                                                <form-input v-model="activity_to_update.contact_name"></form-input>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">フリガナ</label>
                                                                <form-input v-model="activity_to_update.contact_name_kana"></form-input>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-18">
                                                                <label class="form-label">電話番号</label>
                                                                <form-input v-model="activity_to_update.mobile_phone"></form-input>
                                                            </div>
                                                            <div class="col-18">
                                                                <label class="form-label">メールアドレス</label>
                                                                <form-input v-model="activity_to_update.email"></form-input>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">郵便番号</label>
                                                                <div class="input-group">
                                                                    <span class="input-group-text">〒</span>
                                                                    <form-input v-model="activity_to_update.zip_code" @change="searchAddressByZip()"></form-input>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">都道府県</label>
                                                                <form-select-object-search
                                                                    v-model="activity_to_update.area"
                                                                    :options="options_area"
                                                                    option_value="area_id"
                                                                    option_label="area_name"
                                                                ></form-select-object-search>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">市区郡</label>
                                                                <form-input v-model="activity_to_update.city"></form-input>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">町名・番地</label>
                                                                <form-input v-model="activity_to_update.street"></form-input>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">最寄駅</label>
                                                                <form-input v-model="activity_to_update.closest_station"></form-input>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">打合せ方法</label>
                                                                <form-select
                                                                    v-model="activity_to_update.meeting_by"
                                                                    :options="MeetingBy.options()"
                                                                    @selected="changeMeetingBy"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">参加人数</label>
                                                                <form-input-number v-model="activity_to_update.attendance"></form-input-number>
                                                            </div>
                                                            <template v-if="activity_to_update.meeting_by === MeetingBy.TEL">
                                                                <div class="col-12">
                                                                    <label class="form-label">会議参加場所</label>
                                                                    <form-input v-model="activity_to_update.web_meeting_place"></form-input>
                                                                </div>
                                                            </template>
                                                        </div>
                                                        <template v-if="MeetingBy.isWebMeeting(activity_to_update.meeting_by)">
                                                            <div class="row mb-3">
                                                                <div class="col-12">
                                                                    <label class="form-label">Web会議URL</label>
                                                                    <form-input v-model="activity_to_update.web_meeting_url"></form-input>
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">Web会議アカウント</label>
                                                                    <form-input v-model="activity_to_update.web_meeting_account"></form-input>
                                                                </div>
                                                                <div class="col-12">
                                                                    <label class="form-label">会議参加場所</label>
                                                                    <form-input v-model="activity_to_update.web_meeting_place"></form-input>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template v-if="activity_to_update.meeting_by === MeetingBy.COMING">
                                                            <div class="row mb-3">
                                                                <div class="col-18">
                                                                    <label class="form-label">会議室</label>
                                                                    <form-input v-model="activity_to_update.visitor_meeting_room"></form-input>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <div class="row mb-3">
                                                            <div class="col-18">
                                                                <label class="form-label">営業資料</label>
                                                                <form-select-search-multiple
                                                                    v-model="activity_to_update.sales_documents_values"
                                                                    :options="options_document"
                                                                ></form-select-search-multiple>
                                                            </div>
                                                            <div class="col-18">
                                                                <label class="form-label">プレゼント資料</label>
                                                                <form-select-search-multiple
                                                                    v-model="activity_to_update.present_documents_values"
                                                                    :options="options_document"
                                                                ></form-select-search-multiple>
                                                            </div>
                                                        </div>
                                                        <h4 class="mt-5"><i class="bi bi-clipboard-check text-primary"></i> 営業前反社チェック</h4>
                                                        <div class="row mb-3">
                                                            <div class="col-18">
                                                                <label class="form-label">Webサイト</label>
                                                                <form-input v-model="activity_to_update.website"></form-input>
                                                            </div>
                                                            <div class="col-18">
                                                                <label class="form-label">代表者名</label>
                                                                <form-input v-model="activity_to_update.representative_name"></form-input>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">社長名で検索</label>
                                                                <form-select
                                                                    v-model="activity_to_update.check_representative_name"
                                                                    :options="IsProblem.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-36">
                                                                <form-textarea v-model="activity_to_update.check_representative_name_note"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">「社名+KW」で検索</label>
                                                                <form-select
                                                                    v-model="activity_to_update.check_keyword_company_name"
                                                                    :options="IsProblem.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-36">
                                                                <form-textarea v-model="activity_to_update.check_keyword_company_name_note"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">「代表者名+KW」で検索</label>
                                                                <form-select
                                                                    v-model="activity_to_update.check_keyword_representative_name"
                                                                    :options="IsProblem.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-36">
                                                                <form-textarea v-model="activity_to_update.check_keyword_representative_name_note"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">「本社住所+KW」で検索</label>
                                                                <form-select
                                                                    v-model="activity_to_update.check_keyword_address"
                                                                    :options="IsProblem.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-36">
                                                                <form-textarea v-model="activity_to_update.check_keyword_address_note"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <form-checkbox
                                                                    v-model="activity_to_update.is_tradable"
                                                                    option_label="取引可"
                                                                ></form-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-24">
                                                                <label class="form-label">Chatworkメッセージ</label>
                                                                <form-radio
                                                                    v-model="activity_to_update.handle_chatwork"
                                                                    :options="HandleChatwork.options()"
                                                                    :disabled_keys="(activity_to_update.chatwork_message_id ? [] : [HandleChatwork.UPDATE])"
                                                                ></form-radio>
                                                            </div>
                                                        </div>
                                                        <h4 class="mt-5 mb-3"><i class="bi bi-megaphone-fill text-primary"></i> お客様の声</h4>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">場面</label>
                                                                <form-select
                                                                    v-model="activity_to_update.voice_scene"
                                                                    :options="VoiceScene.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">お客様の声</label>
                                                                <form-textarea v-model="activity_to_update.voice_content"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">経緯</label>
                                                                <form-textarea v-model="activity_to_update.voice_background"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">対応</label>
                                                                <form-textarea v-model="activity_to_update.voice_action"></form-textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <!-- 営業報告 -->
                                                <template v-else>
                                                    <div class="modal-body text-start">
                                                        <div class="row mb-3 align-items-end">
                                                            <div class="col-9">
                                                                <label class="form-label">件名</label>
                                                                <form-select
                                                                    v-model="activity_to_update.activity_detailed_type"
                                                                    :options="ActivityDetailedType.meetingOptions()"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-9">
                                                                <label class="form-label">割り当て先 <i class="required"></i></label>
                                                                <form-select-search
                                                                    v-model="activity_to_update.user.user_id"
                                                                    :options="options_user"
                                                                    empty_option="----"
                                                                    :required="true"
                                                                ></form-select-search>
                                                            </div>
                                                            <div class="col-9">
                                                                <label class="form-label">活動日 <i class="required"></i></label>
                                                                <form-input-date v-model="activity_to_update.activity_date" :required=true></form-input-date>
                                                            </div>
                                                            <div class="col-9">
                                                                <form-input-time v-model="activity_to_update.activity_time" :required=true></form-input-time>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">会社規模</label>
                                                                <form-select
                                                                    v-model="activity_to_update.company_scale"
                                                                    :options="CompanyScale.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">従業員数</label>
                                                                <form-input-number v-model="activity_to_update.employee_number"></form-input-number>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">評価</label>
                                                                <form-select
                                                                    v-model="activity_to_update.rank"
                                                                    :options="Rank.options()"
                                                                ></form-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <!--
                                                            <div class="col-12">
                                                                <label class="form-label">サブ評価</label>
                                                                <form-select-object
                                                                    v-model="activity_to_update.detailed_rank"
                                                                    :options="options_detailed_rank"
                                                                    option_value="detailed_rank_id"
                                                                    option_label="detailed_rank_name"
                                                                    empty_option="----"
                                                                    :disabled="is_disabled"
                                                                ></form-select-object>
                                                            </div>
                                                            -->
                                                            <div class="col-12">
                                                                <label class="form-label">見込日</label>
                                                                <form-input-date v-model="activity_to_update.expected_order_date"></form-input-date>
                                                            </div>
                                                            <!--
                                                            <div class="col-12">
                                                                <label class="form-label">優先度</label>
                                                                <form-select-object
                                                                    v-model="activity_to_update.probability"
                                                                    :options="options_probability"
                                                                    option_value="probability_id"
                                                                    option_label="probability_name"
                                                                    empty_option="----"
                                                                    :disabled="is_disabled"
                                                                ></form-select-object>
                                                            </div>
                                                            -->
                                                            <div class="col-12">
                                                                <label class="form-label">確実度</label>
                                                                <form-select-object
                                                                    v-model="activity_to_update.certainty"
                                                                    :options="options_certainty"
                                                                    option_value="certainty_id"
                                                                    option_label="certainty_name"
                                                                    empty_option="----"
                                                                    :disabled="is_disabled"
                                                                ></form-select-object>
                                                            </div>
                                                            <!--
                                                            <div class="col-12">
                                                                <label class="form-label">計測カウント</label>
                                                                <form-select
                                                                    v-model="activity_to_update.count_type"
                                                                    :options="CountType.options()"
                                                                ></form-select>
                                                            </div>
                                                            -->
                                                            <div class="col-12">
                                                                <label class="form-label">競合</label>
                                                                <form-select-object-search-multiple
                                                                    v-model="activity_to_update.competitors_entities"
                                                                    :options="options_competitor"
                                                                    option_value="competitor_id"
                                                                    option_label="competitor_name"
                                                                    :disabled="is_disabled"
                                                                ></form-select-object-search-multiple>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">競合（その他）</label>
                                                                <form-input v-model="activity_to_update.competitor_note"></form-input>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">取得理由</label>
                                                                <form-select
                                                                    v-model="activity_to_update.purpose"
                                                                    :options="Purpose.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                            <div class="col-12">
                                                                <label class="form-label">決算期</label>
                                                                <div class="input-group">
                                                                    <form-input v-model="activity_to_update.fiscal_month"></form-input>
                                                                    <span class="input-group-text">月</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-5 align-items-end">
                                                            <div class="col-18">
                                                                <label class="form-label">規格</label>
                                                                <form-select-search-multiple
                                                                    v-model="activity_to_update.standards_values"
                                                                    :options="Standard.options()"
                                                                ></form-select-search-multiple>
                                                            </div>
                                                            <div class="col-9">
                                                                <label class="form-label">提案金額</label>
                                                                <div class="input-group">
                                                                    <form-input-number v-model="activity_to_update.proposal_price"></form-input-number>
                                                                    <span class="input-group-text">万円</span>
                                                                </div>
                                                            </div>
                                                            <div class="col-9">
                                                                <form-checkbox
                                                                    v-model="activity_to_update.can_video_meeting"
                                                                    option_label="ビデオ通話"
                                                                ></form-checkbox>
                                                            </div>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label class="form-label">追客計画</label>
                                                            <form-textarea v-model="activity_to_update.follow_plan"></form-textarea>
                                                        </div>
                                                        <div class="mb-3">
                                                            <label class="form-label">注意事項</label>
                                                            <form-textarea v-model="activity_to_update.sales_note"></form-textarea>
                                                        </div>
                                                        <!--
                                                        <div class="mb-3">
                                                            <label class="form-label">担当コンサル選定時の注意事項</label>
                                                            <form-textarea v-model="activity_to_update.consultant_note"></form-textarea>
                                                        </div>
                                                        -->
                                                        <div class="mb-3">
                                                            <label class="form-label">内容</label>
                                                            <form-textarea v-model="activity_to_update.meeting_detail"></form-textarea>
                                                        </div>
                                                        <h4 class="mt-5 mb-3"><i class="bi bi-megaphone-fill text-primary"></i> お客様の声</h4>
                                                        <div class="row mb-3">
                                                            <div class="col-12">
                                                                <label class="form-label">場面</label>
                                                                <form-select
                                                                    v-model="activity_to_update.voice_scene"
                                                                    :options="VoiceScene.options()"
                                                                    empty_option="----"
                                                                ></form-select>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">お客様の声</label>
                                                                <form-textarea v-model="activity_to_update.voice_content"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">経緯</label>
                                                                <form-textarea v-model="activity_to_update.voice_background"></form-textarea>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-3">
                                                            <div class="col">
                                                                <label class="form-label">対応</label>
                                                                <form-textarea v-model="activity_to_update.voice_action"></form-textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>

                                                <div class="modal-footer py-4">
                                                    <button v-if="$store.getters['auth/deleteOthersActivity'] || this.$store.state.auth.user_id === activity_to_update.user.user_id" @click="this.$refs.confirmRemove.show();" type="button" class="btn btn-outline-danger mb-0"><i class="bi bi-trash me-2"></i>削除</button>
                                                    <button type="button" class="btn btn-outline-secondary mb-0" data-bs-dismiss="modal" ref="cancel"><i class="bi bi-x"></i> キャンセル</button>
                                                    <button type="submit" class="btn btn-info">保存</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>本当に削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import axios from 'axios';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import Project from '@/models/entities/project';
import Activity from '@/models/entities/activity';
import Area from '@/models/entities/area';
import Probability from '@/models/entities/probability';
import DetailedRank from '@/models/entities/detailed-rank';
import Certainty from '@/models/entities/certainty';
import Competitor from '@/models/entities/competitor';
import ActivityType from '@/models/enums/activity-type';
import ActivityDetailedType from '@/models/enums/activity-detailed-type';
import CanVideoMeeting from '@/models/enums/can-video-meeting';
import HandleChatwork from '@/models/enums/handle-chatwork';
import IsProblem from '@/models/enums/is-problem';
import IsTradable from '@/models/enums/is-tradable';
import IsNotifiedChatwork from '@/models/enums/is-notified-chatwork';
import CompanyScale from '@/models/enums/company-scale';
import Rank from '@/models/enums/rank';
import CountType from '@/models/enums/count-type';
import Purpose from '@/models/enums/purpose';
import MeetingBy from '@/models/enums/meeting-by';
import Status from '@/models/enums/status';
import Standard from '@/models/enums/standard';
import VoiceScene from '@/models/enums/voice-scene';
import FormSelect from '@/components/forms/FormSelect';
import FormRadio from '@/components/forms/FormRadio';
import FormInput from '@/components/forms/FormInput';
import FormInputDate from '@/components/forms/FormInputDate';
import FormInputDatetime from '@/components/forms/FormInputDatetime';
import FormInputTime from '@/components/forms/FormInputTime';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';
import FormSelectObjectSearchMultiple from '@/components/forms/FormSelectObjectSearchMultiple';
import FormCheckbox from '@/components/forms/FormCheckbox';
import FormTextarea from '@/components/forms/FormTextarea';

export default {
    name: 'ProjectActivityHistory',
    components: {
        FormSelect,
        FormRadio,
        FormInput,
        FormInputDate,
        FormInputDatetime,
        FormInputTime,
        FormInputNumber,
        FormSelectObject,
        FormSelectObjectSearch,
        FormSelectSearch,
        FormSelectSearchMultiple,
        FormCheckbox,
        FormTextarea,
        FormSelectObjectSearchMultiple,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    props: {
        project: {
            type: Object,
            default: new Project(),
        },
        options_user: {
            type: Object,
        },
        options_user_object: {
            type: Array,
        },
        options_document: {
            type: Object,
        },
        options_appointment_source: {
            type: Object,
        },
        options_area: {
            type: Object,
        }
    },
    emits: [
        'update',
        'remove',
    ],
    data() {
        return {
            // 編集用
            activity_to_update: new Activity(),

            // enum
            ActivityType,
            ActivityDetailedType,
            CanVideoMeeting,
            HandleChatwork,
            IsProblem,
            IsTradable,
            IsNotifiedChatwork,
            CompanyScale,
            Rank,
            CountType,
            Purpose,
            MeetingBy,
            Status,
            Standard,
            VoiceScene,

            // 開いているアコーディオン indexが入る 配列に存在すれば開いている
            is_open_activities: [],

            is_disabled: false,

            // 事業部によって変わるoption
            options_probability: [],
            options_detailed_rank: [],
            options_certainty: [],
            options_competitor: [],
        }
    },
    methods: {
        openModal(activity) {
            this.activity_to_update = new Activity(JSON.parse(JSON.stringify(activity))); // ディープコピー

            // 営業報告時 優先度、確実度、サブ評価、競合option取得
            if (this.activity_to_update.activity_type === ActivityType.MEETING) {
                this.fetchOptionsByDepartment();
            }
        },
        async fetchOptionsByDepartment() {
            this.is_disabled = true;

            await Promise.all([
                // 優先度
                this.fetchProbabilities(),
                // サブ評価
                this.fetchDetailedRanks(),
                // 確実度
                this.fetchCertainties(),
                // 競合
                this.fetchCompetitors(),
            ]);

            this.is_disabled = false;
        },
        // 優先度マスタ取得
        fetchProbabilities() {
            return new Promise(resolve => {
                this.options_probability.splice(0);

                this.$http.get('/master/probability', {
                    params: {
                        department: this.activity_to_update.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        const probability = new Probability(row);
                        this.options_probability.push(probability);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // サブ評価マスタ取得
        fetchDetailedRanks() {
            return new Promise(resolve => {
                this.options_detailed_rank.splice(0);

                this.$http.get('/master/detailed-rank', {
                    params: {
                        department: this.activity_to_update.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let detailed_rank = new DetailedRank(row);
                        this.options_detailed_rank.push(detailed_rank);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 確実度マスタ取得
        fetchCertainties() {
            return new Promise(resolve => {
                this.options_certainty.splice(0);

                this.$http.get('/master/certainty', {
                    params: {
                        department: this.activity_to_update.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let certainty = new Certainty(row);
                        this.options_certainty.push(certainty);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 競合マスタ取得
        fetchCompetitors() {
            return new Promise(resolve => {
                this.options_competitor.splice(0);

                this.$http.get('/master/competitor', {
                    params: {
                        department: this.activity_to_update.department_id,
                    }
                })
                .then(response => {
                    for (let row of response.data) {
                        let competitor = new Competitor(row);
                        this.options_competitor.push(competitor);
                    }
                })
                .finally(() => {
                    resolve(true);
                })
            })
        },
        // 編集処理
        update() {
            this.startScreenLoading();

            this.$http.put(`/project/${this.project.project_id}/activity/${this.activity_to_update.activity_id}`, this.activity_to_update)
            .then(response => {
                this.$emit('update', response.data);
                this.showMessage('活動履歴を編集しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        },
        // 郵便番号で住所を検索して上書き
        searchAddressByZip() {
            if (!this.activity_to_update.zip_code) {
                return;
            }

            // $http がつくと不要なヘッダがついて cors で弾かれるので、生 axios 使用
            axios.get('https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + this.activity_to_update.zip_code)
            .then(response => {
                if (response.data.results && response.data.results.length > 0) {
                    let record = response.data.results[0];
                    this.activity_to_update.area = new Area({
                        area_id: record.prefcode,
                        area_name: record.address1,
                    });
                    this.activity_to_update.city = record.address2;
                    this.activity_to_update.street = record.address3;
                }
            });
        },

        // アコーディオン開閉 is_open_activitiesの配列に存在したら開いている
        toggleAccordion(index) {
            if (this.is_open_activities.includes(index)) {
                // オープン済みであれば閉じる処理
                const remove_index = this.is_open_activities.indexOf(index);
                this.is_open_activities.splice(remove_index, 1);
            } else {
                // オープンしていなければ開く処理
                this.is_open_activities.push(index);
            }
        },

        // 全てのアコーディオン開く
        openAllAccordion() {
            this.is_open_activities.splice(0);

            for (let i = 0; i < this.project.activities.length; i++) {
                this.is_open_activities.push(i);
            }
        },
        // 全てのアコーディオン閉じる
        closeAllAccordion() {
            this.is_open_activities.splice(0);
        },
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/project/${this.project.project_id}/activity/${this.activity_to_update.activity_id}`)
            .then((response) => {
                this.$emit('remove', response.data);
                // モーダル閉じる
                const index = this.project.activities.findIndex(activity => activity.activity_id === this.activity_to_update.activity_id);
                this.$refs.cancel[index].click();
                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        // 打ち合わせ方法を設定
        changeMeetingBy() {
            this.setWebMeetingUrl();
        },

        // 営業担当のZoom URLに自動設定
        changeSalesUser(selectedUserId) {
            this.activity_to_update.sales_user.user_id = selectedUserId;
            this.setWebMeetingUrl();
        },

        // Web会議URLを設定
        setWebMeetingUrl() {
            this.activity_to_update.web_meeting_url = '';

            // 条件: meeting_by が ZOOM の場合のみ URL を設定
            if (this.activity_to_update.meeting_by === MeetingBy.ZOOM) {
                const selectedSalesUserId = this.activity_to_update.sales_user?.user_id;
                if (selectedSalesUserId) {
                    const salesUser = this.options_user_object.find(function (user) {
                        return user.user_id === selectedSalesUserId;
                    });

                    if (salesUser?.zoom_url) {
                        this.activity_to_update.web_meeting_url = salesUser.zoom_url;
                    }
                }
            }
        }
    },
}
</script>

<style scoped>
    .can-visible {
        max-height: 20rem;
        overflow: scroll;
        /*スクロールバー非表示（IE・Edge）*/
        -ms-overflow-style: none;
        /*スクロールバー非表示（Firefox）*/
        scrollbar-width: none;
    }

    .can-visible::-webkit-scrollbar {
        display: none;
    }
    .section div.row>* {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .accordion-button {padding: .75rem .5rem;}
    .accordion-header button span:first-child {
        flex-basis: 25%;
    }
    .accordion-header button span:nth-child(2) {
    }
    .accordion-header button span:nth-child(3) {
        flex-basis: 45%;
    }
    .fs-7 {font-size: .85rem;}
</style>